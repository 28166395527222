@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-toolbar-data-layers-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');
  $accent: map.get($theme, 'accent');

  .data-layers-container {
    color: mat.get-color-from-palette($foreground, 'text');

    .empty-layers {
      color: mat.get-color-from-palette($foreground, 'disabled-button');
    }

    input {
      background-color: mat.get-color-from-palette($background, 'card');
      color: mat.get-color-from-palette($foreground, 'text');
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
      border-bottom: none;
    }

    mat-expansion-panel {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .definition {
      button {
        border: 1px solid mat.get-color-from-palette($foreground, 'divider');
      }
      .availability {
        background-color: mat.get-color-from-palette($accent, 400);
      }
    }

    .contains-selected {
      color: mat.get-color-from-palette($accent, 500);
    }
  }

  .la-badge-after {
    & > .mat-badge-content {
      background: mat.get-color-from-palette($accent, 500);
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
