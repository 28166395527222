@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-manage-page-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $accent: map.get($theme, 'accent');

  la-manage-page {
    section {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    h2 {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    .badge {
      background-color: mat.get-color-from-palette($accent, 400);
    }

    .empty-pending-list {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
