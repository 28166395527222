@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metrics-collapsible-section-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-metrics-collapsible-section {
    mat-expansion-panel {
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
