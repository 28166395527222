@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-workflow-details-form-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-workflow-details-form {
    color: mat.get-color-from-palette($foreground, 'text');
  }
}
