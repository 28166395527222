@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-print-sidebar-toggle-icon-component($theme) {
  $primary: map.get($theme, 'primary');

  la-print-sidebar-toggle-icon {
    .active {
      color: mat.get-color-from-palette($primary);
    }
  }
}
