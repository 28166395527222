@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-action-error-component($theme) {
  $background: map.get($theme, 'background');

  la-action-error {
    .details {
      background: mat.get-color-from-palette($background, 'app-bar');
    }
  }
}
