@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-table-view-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');
  $is-dark: map.get($theme, 'is-dark');
  $row-background: #f0f0f0;

  la-table-view {
    .wrapper {
      @if $is-dark {
        background-color: mat.get-color-from-palette($background, 'background');
      } @else {
        background-color: mat.get-color-from-palette($background, 'card');
      }
    }

    tbody {
      tr:nth-child(even) {
        @if $is-dark {
          background-color: mat.get-color-from-palette($primary);
        } @else {
          background-color: $row-background;
        }
      }
    }
  }
}
