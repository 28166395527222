@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-payment-method-section-component($theme) {
  $primary: map.get($theme, 'primary');

  la-payment-method-section {
    .invalid {
      color: mat.get-color-from-palette(mat.$red-palette, 500);
    }

    .valid {
      color: mat.get-color-from-palette($primary);
    }
  }
}
