@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-users-list-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-users-list {
    .mat-header-cell {
      color: mat.get-color-from-palette($primary);

      &:last-of-type {
        color: mat.get-color-from-palette($foreground, 'base');
      }
    }

    .check {
      color: mat.get-color-from-palette($primary);
    }
  }
}
