@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-print-details-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);

  la-print-details {
    .user-details,
    .print-name h3,
    .map-name,
    .size-details {
      color: mat.get-color-from-palette($primary, 'text');
    }

    mat-icon,
    .job-code,
    .date {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .action {
      color: mat.get-color-from-palette(mat.$blue-palette, 600);
    }

    .deleted {
      color: mat.get-color-from-palette($warn, 'text');
    }
  }
}
