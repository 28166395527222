@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-data-layer-reports-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-data-layer-reports {
    color: mat.get-color-from-palette($foreground, 'text');

    .main {
      background-color: mat.get-color-from-palette($background, 'card');
    }
  }
}
