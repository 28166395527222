@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-archived-layers-list($theme) {
  $background: map.get($theme, 'background');

  table {
    border-top: 1px solid mat.get-color-from-palette($background, 'disabled-button-toggle');
    border-bottom: 1px solid mat.get-color-from-palette($background, 'disabled-button-toggle');
  }
}
