@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-buffer-tool-component($theme) {
  $background: map.get($theme, 'background');

  la-buffer-tool {
    .la-floating-panel {
      background-color: mat.get-color-from-palette($background, 'background');
    }
  }
}
