@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-import-from-files-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-import-from-files {
    .upload-file-info {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
    .projection {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .extension {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .subfile {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .uploader {
      border: 3px dashed mat.get-color-from-palette($foreground, 'divider');
    }

    .uploading {
      border: 3px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
