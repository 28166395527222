@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-main($theme) {
  $background: map.get($theme, background);

  la-root {
    background-color: mat.get-color-from-palette($background, 'background');
  }
}

@mixin la-link($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);

  .la-link,
  .la-link:link,
  .la-link:visited,
  .la-link:active,
  .la-link:focus {
    color: mat.get-color-from-palette($primary, 'text');
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  .la-link:hover {
    color: mat.get-color-from-palette($primary, 'text');
    text-decoration: underline;
  }

  .la-link.disabled {
    color: mat.get-color-from-palette($foreground, 'disabled-text');
    text-decoration: none;
    cursor: inherit;
  }
}

@mixin la-label($theme) {
  $foreground: map.get($theme, foreground);

  .la-label {
    font-size: 13px;
    line-height: 24px;
    color: mat.get-color-from-palette($foreground, 'secondary-text');

    &--hr {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
      margin-bottom: 12px;
    }
  }
}

@mixin la-gold-checkbox($theme) {
  $accent: map.get($theme, 'accent');
  .la-gold-checkbox {
    .mat-pseudo-checkbox-checked {
      background: mat.get-color-from-palette($accent, 700) !important;
    }
  }
}

@mixin la-normalize-team-intput($theme) {
  .la-normalize-team-input {
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

@mixin la-normalize-form-field($theme) {
  .mat-mdc-form-field-infix {
    display: flex;

    .mdc-evolution-chip-set {
      width: 100%;
    }
  }

  .la-filter-field {
    display: block;
    @include mat.form-field-density(-5);

    .mat-mdc-select {
      display: flex;
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon,
    .mat-mdc-form-field-icon-suffix > .mat-icon {
      padding: 8px;
      font-size: 20px;
      height: 20px;
      width: 20px;
    }

    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }
}

@mixin la-small-button($theme) {
  .la-small-button {
    .mat-mdc-button-touch-target {
      height: 28px !important;
      width: 28px !important;
    }
  }
}

@mixin la-checkbox($theme) {
  .la-checkbox {
    .mat-checkbox-layout {
      white-space: pre-line;
      word-break: break-all;
    }
  }
}

@mixin la-ellipsis-checkbox($theme) {
  .la-ellipsis-checkbox {
    width: 100%;

    .mat-checkbox-layout {
      width: 100%;
    }

    .mat-checkbox-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

@mixin la-nested-list($theme) {
  .la-nested-list {
    &.mat-list-base .mat-list-item {
      height: auto;
      & .mat-list-item-content {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        & .mat-list-base {
          padding-top: 0;
        }
      }
    }
  }
}

@mixin la-floating-panel($theme) {
  .la-floating-panel {
    height: 100%;
    position: relative;
    border-radius: 5px;
  }
}

@mixin la-tabs($theme) {
  .la-tabs {
    mat-tab-group {
      height: 100%;

      &.hide-tabs {
        mat-tab-header {
          display: none;
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      height: 100%;
    }

    mat-tab-body {
      flex: 1;
    }

    .mat-mdc-tab-body-content {
      overflow: unset;
    }
  }
}

@mixin la-tabs-body($theme) {
  .la-tabs-body {
    .mat-mdc-tab-body-wrapper {
      margin: 20px 0;
    }
  }
}
@mixin la-tabs-body-take-all-space($theme) {
  .la-tabs-body-take-all-space {
    .mat-mdc-tab-label {
      padding: 0;
      min-width: 150px;
    }
    .mat-mdc-tab-body-wrapper {
      flex: 1 1 auto;
    }
  }
}

@mixin la-list($theme) {
  .la-list {
    &.mat-list-base .mat-list-item {
      height: auto;

      & .mat-list-item-content {
        display: block;
        padding: 0 0 15px;
      }
    }
  }
}

@mixin la-dense-select-list($theme) {
  .la-dense-select-list {
    .mdc-list-item--with-trailing-radio.mdc-list-item {
      padding: 0 16px;
    }

    .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
      display: none;
    }
  }
}

@mixin la-datepicker($theme) {
  .la-datepicker {
    & .mat-form-field-flex {
      flex-direction: row-reverse;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
      width: 1.4em;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-infix {
      margin-left: 5px;
    }
  }

  .la-datepicker-button {
    &.mdc-icon-button,
    .mat-unthemed {
      height: 24px !important;
      padding: 0px !important;
    }

    &.mat-mdc-focus-indicator,
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
    .mat-mdc-icon-button .mat-mdc-button-ripple {
      top: -6px;
      bottom: 6px;
      left: 6px;
      position: absolute;
    }
  }

  .la-datepicker-toggle {
    .mdc-icon-button__ripple,
    .mat-ripple {
      height: 36px;
      width: 36px;
    }
  }
}

@mixin la-menu-panel($theme) {
  .la-flexible-menu-panel {
    &.mat-mdc-menu-panel {
      min-width: auto;
      min-height: auto;
    }
  }
}

@mixin la-slide-toggle($theme) {
  .la-slide-toggle {
    & .mat-slide-toggle-label {
      justify-content: space-between;
    }
  }
  .la-slide-toggle-icon {
    .mdc-switch__icons {
      display: none;
    }

    .mdc-switch .mdc-switch__handle {
      height: 20px;
      width: 20px;
    }
  }
}

@mixin la-radio-button-label($theme) {
  .la-radio-button-label {
    & .mdc-form-field {
      align-items: flex-start;

      .mdc-radio {
        padding: 5px;
      }
    }
  }
}

@mixin la-radio-button($theme) {
  .la-radio-button {
    & .mdc-form-field .mdc-radio {
      padding: 5px;
    }
  }
}

@mixin la-menu($theme) {
  .la-menu {
    & .mat-mdc-menu-content:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@mixin la-chip($theme) {
  mat-chip {
    .mdc-evolution-chip__text-label,
    .mdc-evolution-chip__icon--trailing {
      color: unset !important;
    }

    .mdc-evolution-chip__text-label {
      font-weight: 500;
    }
  }
}

@mixin la-badge-after($theme) {
  .la-badge-after {
    &.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
      right: -8px;
      top: -8px;
    }
  }
}

@mixin la-drag-preview($theme) {
  $background: map.get($theme, 'background');

  .la-drag-preview {
    &.cdk-drag-preview {
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      background-color: mat.get-color-from-palette($background, 'card');

      & td {
        border-bottom: none;
        font-size: 12px;

        &:first-of-type {
          font-weight: 500;
          padding-left: 30px;
          font-size: 14px;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }
}

@mixin la-report-drag-preview($theme) {
  $background: map.get($theme, 'background');

  .la-report-drag-preview {
    &.cdk-drag-preview {
      border-radius: 4px;
      box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;

      cursor: pointer;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px 0 20px;
      font-size: 12px;
      background-color: mat.get-color-from-palette($background, 'card');

      &.move-cursor {
        cursor: all-scroll;
      }

      .report-name {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .progress-state {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        height: 100%;

        .progress {
          width: 159px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .generating-your-report {
          font-size: 16px;
        }
      }

      .actions {
        max-width: 10%;
        text-align: right;
      }

      & > div {
        flex: 1;
      }
    }
  }
}

@mixin la-plans-drag-preview($theme) {
  $background: map.get($theme, 'background');

  .la-plans-drag-preview {
    &.cdk-drag-preview {
      border-radius: 4px;
      box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      background-color: mat.get-color-from-palette($background, 'card');
      opacity: 0.7;

      &.move-cursor {
        cursor: all-scroll;
      }
    }
  }
}

@mixin la-normalize-mat-error($theme) {
  html {
    --mat-form-field-subscript-text-tracking: normal;
  }
}

@mixin la-plans-drag-placeholder($theme) {
  $background: map.get($theme, 'background');

  .la-plans-drag-placeholder {
    &.cdk-drag-placeholder {
      display: none;
    }
  }
}

@mixin la-narrow-input($theme) {
  .la-narrow-input {
    .mat-mdc-text-field-wrapper {
      padding-right: 10px;
    }
  }
}

@mixin la-teams-table($theme) {
  .la-teams-table {
    .mdc-data-table__content {
      vertical-align: top;
    }
  }
}

@mixin la-select-search($theme) {
  $foreground: map.get($theme, foreground);

  .la-select-search {
    max-height: calc(100vh - 350px) !important;
  }

  .mat-select-search-input {
    height: 48px !important;
  }

  .mat-select-search-hidden {
    height: 49px !important;
  }

  .mat-select-search-no-entries-found {
    text-align: center;
    font-size: 16px;
    padding: 8px 0;
    margin-top: 12px;
    display: inline-block;
    color: mat.get-color-from-palette($foreground, 'disabled-text');
  }
}

@mixin la-paginator($theme) {
  .la-paginator {
    .mat-mdc-paginator-range-label {
      display: none;
    }
  }
}

@mixin la-warning($theme) {
  $accent: map.get($theme, 'accent');
  $foreground: map.get($theme, 'foreground');
  .la-warning {
    color: mat.get-color-from-palette($foreground, 'text');
    background-color: mat.get-color-from-palette($accent, 100);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
  }
}

@mixin la-attribute-select($theme) {
  $foreground: map.get($theme, 'foreground');

  .la-attribute-select {
    .mat-mdc-select-placeholder {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}

@mixin la-donut-chart($theme) {
  $foreground: map.get($theme, 'foreground');

  .la-donut-chart {
    .apexcharts-datalabel-label {
      font-size: 24px !important;
    }

    .apexcharts-datalabel-value {
      font-size: 12px !important;
    }
  }
}

@mixin la-ellipsis-chip($theme) {
  .la-ellipsis-chip {
    .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
    .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
    .mat-mdc-standard-chip .mat-mdc-chip-action-label {
      overflow: hidden;
    }

    .mdc-evolution-chip-set__chips {
      max-width: 100%;
    }
  }
}

@mixin la-custom-elements($theme) {
  @include la-main($theme);
  @include la-link($theme);
  @include la-label($theme);
  @include la-normalize-form-field($theme);
  @include la-gold-checkbox($theme);
  @include la-checkbox($theme);
  @include la-ellipsis-checkbox($theme);
  @include la-nested-list($theme);
  @include la-floating-panel($theme);
  @include la-tabs($theme);
  @include la-tabs-body($theme);
  @include la-tabs-body-take-all-space($theme);
  @include la-list($theme);
  @include la-dense-select-list($theme);
  @include la-datepicker($theme);
  @include la-menu-panel($theme);
  @include la-slide-toggle($theme);
  @include la-radio-button-label($theme);
  @include la-radio-button($theme);
  @include la-menu($theme);
  @include la-chip($theme);
  @include la-badge-after($theme);
  @include la-drag-preview($theme);
  @include la-report-drag-preview($theme);
  @include la-plans-drag-preview($theme);
  @include la-plans-drag-placeholder($theme);
  @include la-normalize-mat-error($theme);
  @include la-narrow-input($theme);
  @include la-small-button($theme);
  @include la-normalize-team-intput($theme);
  @include la-teams-table($theme);
  @include la-select-search($theme);
  @include la-paginator($theme);
  @include la-warning($theme);
  @include la-attribute-select(($theme));
  @include la-donut-chart(($theme));
  @include la-ellipsis-chip(($theme));
}
