@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-photo-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, 'foreground');

  footer {
    & > div {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');

      & > span {
        color: mat.get-color-from-palette($primary, 'text');
      }
    }
  }
}
