@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-workflow-details-price-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-workflow-details-price {
    color: mat.get-color-from-palette($foreground, 'text');
  }
}
