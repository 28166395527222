@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-print-sidebar-component($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');

  la-print-sidebar {
    background: mat.get-color-from-palette($background, card);

    .back-button {
      background-color: mat.get-color-from-palette($background, 'app-bar');
    }

    .title {
      background-color: mat.get-color-from-palette($background, 'status-bar');
      color: mat.get-color-from-palette($foreground, 'text');
    }

    .clickable {
      &:hover {
        cursor: pointer;
        background-color: mat.get-color-from-palette($primary, 100);
      }
    }
  }
}
