@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-farm-metrics-page-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  $accent: map.get($theme, 'accent');

  la-farm-metrics-page {
    .header {
      color: mat.get-color-from-palette($background, 'card');
      background-color: #365365;
    }

    .sub-header {
      color: mat.get-color-from-palette($background, 'card');
      background-color: #62c0c5;
    }

    .holding-page {
      border: 1px solid rgba(224, 224, 224, 1);
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
