@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-choose-template-card-component($theme) {
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');

  la-choose-template-card {
    .badge {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($background, 'card');
    }

    mat-icon {
      color: mat.get-color-from-palette($primary);
    }
  }
}
