@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-photo-attribute-component($theme) {
  $primary: map.get($theme, primary);

  la-photo-attribute {
    .multiple,
    .empty,
    .empty-codes {
      color: mat.get-color-from-palette($primary);
    }
  }
}
