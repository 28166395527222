@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-expired-invitation-page-error-component($theme) {
  $blue: #1b5569;
  $light-grey: #f2f2f2;

  la-expired-invitation-page-error {
    p {
      color: $blue;
    }

    .message {
      border: 1px solid $blue;
      background-color: $light-grey;
      color: $blue;
    }
  }
}
