@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-subscription-estimated-purchase-summary-component($theme) {
  $primary: map.get($theme, 'primary');

  la-subscription-estimated-purchase-summary {
    border: 2px solid mat.get-color-from-palette($primary);

    .confirmation-title {
      label {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
}
