@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-header-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');

  la-header {
    background: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($foreground, 'terxt');
  }
}
