@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-zoom-level-component($theme) {
  $background: map.get($theme, 'background');
  $accent: map.get($theme, 'accent');

  la-zoom-indicator {
    & .zoom {
      background-color: mat.get-color-from-palette($background, 'card');

      &.premium {
        background-color: mat.get-color-from-palette($accent, 400);
      }
    }

    & .credits,
    & .interactive-map {
      background-color: mat.get-color-from-palette($background, 'card');
    }
  }
}
