@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-member-teams-component($theme) {
  $primary: map.get($theme, 'primary');

  la-member-teams {
    mat-hint {
      color: mat.get-color-from-palette($primary);
    }
  }
}
