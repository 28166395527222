@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-data-layers-table-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-data-layers-table {
    .report {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'disabled-button');
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'disabled-button');

      table {
        .no-results,
        th {
          border-bottom: 1px solid mat.get-color-from-palette($foreground, 'disabled-button');
        }

        tr:nth-child(2n):not(.no-results) {
          background: mat.get-color-from-palette($background, 'disabled-list-option');
        }
      }
    }

    .action {
      & mat-icon {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      & span {
        color: mat.get-color-from-palette(mat.$light-blue-palette, 700);
      }
    }
  }
}
