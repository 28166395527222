@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-billing-page-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-billing-page {
    la-section {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
