@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metrics-template-page-component($theme) {
  $background: map.get($theme, 'background');

  la-metrics-template-page {
    background-color: mat.get-color-from-palette($background, 'status-bar');

    .sticky {
      background-color: mat.get-color-from-palette($background, 'status-bar');
    }
  }
}
