@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-frames-list-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-frames-list {
    mat-icon {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .search input {
      border-color: mat.get-color-from-palette($foreground, 'divider');

      &:focus {
        border-color: mat.get-color-from-palette($primary);
      }
    }

    .frame {
      background-color: mat.get-color-from-palette($background, 'background');

      &.selected {
        background-color: mat.get-color-from-palette($background, 'status-bar');
      }

      &.selected,
      &:hover {
        border-color: mat.get-color-from-palette($primary);
      }
    }

    .title {
      &__expired {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      &__empty {
        color: mat.get-color-from-palette($foreground, 'divider');
      }
    }

    .creator {
      color: mat.get-color-from-palette($primary, 300);
    }

    .extra,
    .empty {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
