@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-feature-resizer($theme) {
  $foreground: map.get($theme, 'foreground');

  la-feature-resizer {
    input {
      background: transparent;
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
