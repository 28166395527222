@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-coordinates-component($theme) {
  $primary: map.get($theme, primary);

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(mat.get-color-from-palette($primary, 'text'), 0.5);
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: rgba(mat.get-color-from-palette($primary, 'text'), 1);
  }

  la-coordinates {
    & .copied {
      color: mat.get-color-from-palette($primary);
    }
  }
}
