@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-content-with-image-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');

  la-content-with-image {
    h2 {
      color: mat.get-color-from-palette($primary, 'text');
    }

    p {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
