@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-subscription-section-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-subscription-section {
    .enabled {
      color: mat.get-color-from-palette($primary);
    }

    .disabled {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
