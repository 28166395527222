@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-teams-category-form-component($theme) {
  $primary: map.get($theme, 'primary');

  la-teams-category-form {
    .buttons--confirm {
      color: mat.get-color-from-palette($primary);
    }
  }
}
