@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-credits-top-up-dialog-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-credits-top-up-dialog {
    .wrapper {
      & > p,
      & mat-radio-button {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      & mat-radio-button:not(:first-of-type) {
        & p {
          & span:last-of-type {
            color: mat.get-color-from-palette($primary);
          }
        }
      }
    }

    .success {
      color: mat.get-color-from-palette(mat.$green-palette, 600);
    }

    mat-error {
      color: mat.get-color-from-palette(mat.$red-palette, 500);
    }
  }
}
