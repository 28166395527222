@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-add-data-layers-report-dialog-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-add-data-layers-report-dialog {
    .area > p,
    .actions > div > p {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .success {
      color: mat.get-color-from-palette(mat.$green-palette, 600);
    }
  }
}
