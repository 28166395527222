@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metrics-table-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-metrics-table {
    .mat-mdc-footer-cell {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
