@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-members-invite-page-component($theme) {
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-members-invite-page {
    .invitee {
      background-color: mat.get-color-from-palette($background, 'app-bar');
    }

    .actions {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .payment-method-success {
      color: mat.get-color-from-palette($primary);
    }
  }
}
