@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metrics-page-component($theme) {
  $primary: map.get($theme, 'primary');

  la-metrics-page mat-nav-list {
    a[activated='true'] {
      border-left: 2px solid mat.get-color-from-palette($primary);
      * {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
}
