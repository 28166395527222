@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-choose-template-for-files-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-choose-template-for-files {
    .choose-template,
    .no-files,
    .extension {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .dropdown-container {
      border: 3px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
