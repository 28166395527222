@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-user-profile-edit-dialog-component($theme) {
  $primary: map.get($theme, 'primary');

  la-user-profile-edit-dialog {
    u {
      color: mat.get-color-from-palette($primary);
    }
  }
}
