@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-portfolio-right-hand-panel-component($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, primary);

  la-portfolio-right-hand-panel {
    section {
      background-color: mat.get-color-from-palette($background, 'card');
    }

    header {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    .dock-icon {
      background-color: mat.get-color-from-palette($primary, 500);

      & mat-icon {
        color: mat.get-color-from-palette($background, 'card');
      }
    }
  }
}
