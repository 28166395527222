@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-land-registry-ownership-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, 'foreground');

  la-land-registry-ownership {
    & .copied {
      color: mat.get-color-from-palette($primary);
    }

    & p {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
