@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-referral-list-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-referral-list {
    .mat-header-cell {
      color: mat.get-color-from-palette($primary);
    }

    .earned {
      color: mat.get-color-from-palette($foreground, 'text', 0.2);

      &.accepted {
        color: mat.get-color-from-palette($foreground, 'text');
      }
    }
  }
}
