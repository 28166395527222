@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-contextual-header-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');
  $primary: map.get($theme, 'primary');
  $is-dark: map.get($theme, 'is-dark');

  la-contextual-header {
    color: mat.get-color-from-palette($foreground, 'text');

    .credit {
      color: mat.get-color-from-palette($primary);
    }

    .mat-stroked-button {
      border-color: mat.get-color-from-palette($primary) !important;
    }

    .person mat-icon {
      color: mat.get-color-from-palette($background, 'card');
      background: mat.get-color-from-palette($primary);
    }

    @if $is-dark {
      background-color: mat.get-color-from-palette($background, 'app-bar');
    } @else {
      background-color: mat.get-color-from-palette($background, 'card');
    }
  }
}
