@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-measure-tool-preview-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, 'foreground');

  $background-color: rgba(255, 255, 255, 0.8);
  $box-shadow: 0 0 5px #00000078;

  la-measure-tool-preview {
    background: $background-color;
    box-shadow: $box-shadow;

    .options > button {
      background: $background-color;
      box-shadow: $box-shadow;
    }

    .copied-button {
      color: mat.get-color-from-palette($primary);
    }
  }
}
