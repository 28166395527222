@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-see-more-page-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-see-more-page {
    .nav-bar {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
