@use 'sass:map';
@use '@angular/material' as mat;

@import '../../../../styles/mixins';

@mixin la-join-organization-page-component($theme) {
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-join-organization-page {
    color: mat.get-color-from-palette($foreground, 'text');
    .title {
      color: mat.get-color-from-palette($primary);
    }

    mat-icon {
      color: mat.get-color-from-palette($foreground, 'icon');
    }

    input {
      @include autocompleteColor($background, $foreground);
    }
  }
}
