@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-print-sidebar-elements-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');

  la-print-sidebar-elements {
    fieldset,
    mat-expansion-panel,
    .header {
      box-shadow: none !important;
      border: 0;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    mat-expansion-panel-header,
    .mat-expansion-panel-header[aria-disabled='true'] {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    .legend-image-box hr {
      border: 0;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .header la-action {
      mat-icon {
        color: mat.get-color-from-palette($foreground, 'text');
      }

      .disabled mat-icon {
        color: mat.get-color-from-palette($foreground, 'disabled-text');
      }
    }
  }
}
