@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-land-declarations-table-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $warn: map.get($theme, warn);

  la-land-declarations-table {
    .no-data-row {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    mat-icon {
      color: mat.get-color-from-palette($warn, 'text');
    }
  }
}
