@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-dialog-header-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-dialog-header {
    button {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
