@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-action-component($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-action {
    span {
      color: mat.get-color-from-palette($primary, 'text');

      &:hover {
        color: mat.get-color-from-palette($primary, 'text');
      }

      &.disabled {
        color: mat.get-color-from-palette($foreground, 'disabled-text');
      }
    }
  }
}
