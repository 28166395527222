@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-unassigned-features-metrics-component($theme) {
  la-unassigned-features-metrics {
    .metric-value {
      color: rgba(54, 83, 101, 1);

      &.blue {
        color: rgba(98, 192, 197, 1);
      }
    }
  }
}
