@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-layer-toolbar-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');
  $accent: map.get($theme, 'accent');
  $is-dark-theme: map.get($theme, 'is-dark');

  la-layer-toolbar {
    @if $is-dark-theme {
      background-color: rgba(124, 124, 124, 0.6);
    } @else {
      background-color: rgba(216, 216, 216, 0.6);
    }

    .mat-mdc-unelevated-button.finish {
      background-color: #549ae6;
      color: #fff;
    }
  }
}
