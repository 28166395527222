@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-new-plan-dialog-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-choose-template,
  la-import-method {
    mat-card:hover:not(.disabled) {
      border-color: mat.get-color-from-palette($primary, 500);
    }
  }

  la-import-from-rpa-component {
    .step {
      a {
        color: mat.get-color-from-palette($primary);
      }

      .description {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
    }

    .file-too-big {
      span {
        color: mat.get-color-from-palette(mat.$red-palette, 700);
      }
    }
  }
}
