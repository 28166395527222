@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-import-method-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-import-method {
    .disabled {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
