@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-panel-header-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-panel-header {
    background-color: mat.get-color-from-palette($background, 'app-bar');

    h2 {
      background-color: mat.get-color-from-palette($background, 'status-bar');
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
