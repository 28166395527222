@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-data-purchases-details-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');
  $warn: map.get($theme, warn);

  la-data-purchases-details {
    & .name > mat-icon,
    & .action > mat-icon,
    & .content-right > span,
    & .column-right span,
    & .map {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    & .name > span,
    & .action {
      color: mat.get-color-from-palette(mat.$blue-palette, 600);
    }

    & .content-right > h3 {
      color: mat.get-color-from-palette($primary);
    }

    & .deleted {
      color: mat.get-color-from-palette($warn, 'text');
    }
  }
}
