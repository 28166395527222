@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-print-new-extent-component($theme) {
  $warn: map.get($theme, warn);

  la-print-new-extent {
    .error {
      color: mat.get-color-from-palette($warn, 'text');
    }
  }
}
