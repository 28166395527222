@mixin la-small-screen {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin la-medium-screen {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin la-normal-screen {
  @media (min-width: 1025px) and (max-width: 1399px) {
    @content;
  }
}

@mixin la-large-screen {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin autocompleteColor($background, $color) {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px mat.get-color-from-palette($background, 'background') inset !important;
    -webkit-text-fill-color: mat.get-color-from-palette($color, 'base') !important;
  }
}
