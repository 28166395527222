@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-import-from-rpa-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-import-from-rpa {
    .step {
      a {
        color: mat.get-color-from-palette($primary);
      }

      .description {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
    }

    .file-too-big {
      span {
        color: mat.get-color-from-palette(mat.$red-palette, 700);
      }
    }
  }
}
