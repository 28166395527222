@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-no-permissions-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-no-permissions {
    p {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
