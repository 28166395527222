@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-merge-notification-bat-component($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');
  $accent: map.get($theme, 'accent');

  la-merge-notification-bar {
    background-color: mat.get-color-from-palette($foreground, 'text');
    color: mat.get-color-from-palette($background, 'card');

    & button.mat-raised-button {
      background-color: mat.get-color-from-palette($accent, 400);
    }
  }
}
