@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-unlock-portfolio-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-unlock-portfolio {
    color: mat.get-color-from-palette($foreground, 'text');
  }
}
