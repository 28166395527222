@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-plans-tree-layer-header-component($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');

  la-plans-tree-layer-header {
    & .published,
    & .locked {
      color: mat.get-color-from-palette($primary, 400);

      &[disabled] {
        color: mat.get-color-from-palette($foreground, 'disabled-text');
      }
    }
  }
}
