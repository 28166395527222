@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-table-view-dialog-component($theme) {
  $background: map.get($theme, background);

  la-table-view-dialog {
    background-color: mat.get-color-from-palette($background, 'background');
  }
}
