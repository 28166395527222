@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-right-hand-panel-component($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-right-hand-panel {
    h3,
    header p:first-of-type,
    .close-icon,
    mat-slide-toggle {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    .dock-icon {
      background-color: mat.get-color-from-palette($primary, 500);

      & mat-icon {
        color: mat.get-color-from-palette($background, 'card');
      }
    }

    section {
      background-color: mat.get-color-from-palette($background, 'card');
    }

    .color-mark-wrapper {
      border: 1px solid mat.get-color-from-palette($foreground, 'text', 0.2);
    }

    .tabs .mat-tab-label-content,
    .upgrade {
      color: mat.get-color-from-palette($primary, 'text');
    }

    footer {
      & > div {
        border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');

        & > span {
          color: mat.get-color-from-palette($primary, 'text');
        }
      }
    }
  }
}
