@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-referral-make-section-component($theme) {
  $background: map.get($theme, 'background');

  la-referral-make-section {
    main {
      background-color: mat.get-color-from-palette($background, 'app-bar');
    }
  }
}
