@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-workflow-list-component($theme) {
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');
  $foreground: map.get($theme, 'foreground');

  la-workflow-list {
    color: mat.get-color-from-palette($foreground, 'text');
    .data-workflow {
      border: 2px solid mat.get-color-from-palette($foreground, 'slider-off');

      &:hover {
        border: 2px solid mat.get-color-from-palette($primary);
      }
    }

    .disabled {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .metadata {
      & > span {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
      & > div {
        border: 1px solid mat.get-color-from-palette($foreground, 'slider-min');
        color: mat.get-color-from-palette($foreground, 'slider-min');
      }
      & > .premium {
        background-color: mat.get-color-from-palette($accent);
      }
      & > .disabled {
        background-color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
    }
  }
}
