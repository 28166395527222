@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-farmer-sharing-page-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');

  la-farmer-sharing-page {
    $foreground: map.get($theme, 'foreground');
    $primary: map.get($theme, 'primary');
    $background: map.get($theme, 'background');

    la-farmer-nature-reporting-page {
      la-section {
        border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
      }

      mat-expansion-panel-header {
        mat-icon {
          background-color: mat.get-color-from-palette($primary);
          color: mat.get-color-from-palette($background, 'card') !important;
        }
      }
    }
  }
}
