@use 'sass:map';
@use '@angular/material' as mat;

@mixin ol-zoom($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  .ol-zoom {
    top: auto;
    bottom: 35px;
    right: auto;
    left: 9px;
    padding: 0;
    border-radius: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);

    button {
      color: mat.get-color-from-palette($foreground, 'text');
      background-color: mat.get-color-from-palette($background, 'raised-button');
      margin: 0;
      width: 32px;
      height: 32px;

      &:hover,
      &:focus {
        color: mat.get-color-from-palette($foreground, 'text');
        background-color: mat.get-color-from-palette($background, 'raised-button');
        cursor: pointer;
        outline: none;
      }

      + button {
        border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
      }
    }
  }
}

@mixin ol-scale($theme) {
  .ol-scale-line {
    top: auto;
    right: auto;
    left: 5px;
    bottom: 0;
    background: transparent;
    position: relative;
    padding-bottom: calc(0.35 * max(var(--x), var(--y)) * 0.01);
  }

  .ol-scale-line-inner {
    color: black;
    border-color: black;
    border-width: 2px;
    font-size: calc(0.8 * max(var(--x), var(--y)) * 0.01);
    line-height: calc(0.7 * max(var(--x), var(--y)) * 0.01);
    padding-bottom: calc(0.1 * max(var(--x), var(--y)) * 0.01);
  }
}

@mixin ol-copyright($theme) {
  .ol-attribution {
    font-size: 11px;
    opacity: 0.7;
    right: 0;
  }
}

@mixin la-ol-elements($theme) {
  @include ol-zoom($theme);
  @include ol-scale($theme);
  @include ol-copyright($theme);
}
