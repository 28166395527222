@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-maps-grid-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-maps-grid {
    mat-card:hover {
      border-color: mat.get-color-from-palette($primary, 500);

      &.archived:hover {
        border-color: mat.get-color-from-palette($foreground, 'disabled-button');
      }
    }

    mat-icon {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .creator {
      color: mat.get-color-from-palette($primary, 300);
    }

    .name {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    .permission,
    .created,
    .updated {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
