@use 'sass:map';
@use '@angular/material' as mat;
@import '../../styles/variables';
@mixin before-after-common {
  display: block;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
}

@mixin la-plans-tree-component($theme) {
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-plans-tree {
    mat-nested-tree-node:not(.parcel):before {
      cursor: pointer;
      @include before-after-common;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
      z-index: 1;
    }

    & mat-tree > mat-nested-tree-node:first-of-type:before {
      border-bottom: none;
    }

    & mat-tree > mat-nested-tree-node:last-of-type:after {
      @include before-after-common;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .drop-inside {
      border: 2px dashed mat.get-color-from-palette($primary);
    }

    .drop-before {
      border-top: 2px dashed mat.get-color-from-palette($primary);
    }

    .drop-after {
      border-bottom: 2px dashed mat.get-color-from-palette($primary);
    }

    & la-plans-tree-folder-header.selected:before {
      @include before-after-common;
      height: $node-height;
      background-color: mat.get-color-from-palette($accent, 100);
    }

    & la-plans-tree-layer-header.selected:before {
      @include before-after-common;
      height: $node-height;
      background-color: mat.get-color-from-palette($accent, 300);
    }

    & la-plans-tree-parcel-header.selected:before {
      @include before-after-common;
      height: $node-height;
      background-color: mat.get-color-from-palette($accent, 500);
    }

    & la-plans-tree-feature-header.selected:before {
      @include before-after-common;
      height: $node-height;
      background-color: mat.get-color-from-palette($accent, 700);
    }
  }
}
