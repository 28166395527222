@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-attribute-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, 'foreground');

  la-attribute {
    .more {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .multiple,
    .empty,
    .empty-codes {
      color: mat.get-color-from-palette($primary);
    }
  }
}
