@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-left-hand-panel-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-left-hand-panel {
    background-color: mat.get-color-from-palette($background, 'card');

    header,
    footer {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    article {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .overlay-spinner {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
  }
}
