@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-data-layers-list-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');
  $accent: map.get($theme, 'accent');

  la-data-layers-list {
    .progress-error {
      color: mat.get-color-from-palette(mat.$red-palette, 700);
    }
    .report {
      color: mat.get-color-from-palette($foreground, 'text');
      .report-header {
        background: mat.get-color-from-palette($background, 'card');
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid mat.get-color-from-palette($background, 'disabled-button-toggle');
      }
    }
    & > header {
      background-color: mat.get-color-from-palette($background, 'card');
      border-bottom: 1px solid mat.get-color-from-palette($background, 'disabled-button-toggle');

      & > div {
        & > span {
          color: mat.get-color-from-palette($primary);
        }

        & > mat-icon {
          color: mat.get-color-from-palette($foreground, 'disabled-button');

          &.move-enabled {
            color: mat.get-color-from-palette($accent, 500);
          }
        }
      }
    }

    .expand-icon {
      color: mat.get-color-from-palette($primary);
    }

    .empty {
      color: mat.get-color-from-palette($foreground, 'disabled-button');
    }

    .disabled {
      color: mat.get-color-from-palette($foreground, 'disabled-button');
    }

    .button-create:disabled {
      color: mat.get-color-from-palette($background, 'card');
      background-color: mat.get-color-from-palette(mat.$gray-palette, 500);
    }
  }
}
