@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-publish-section-component($theme) {
  la-publish-section {
    .pending {
      color: mat.get-color-from-palette(mat.$grey-palette, 600);
    }

    .accepted {
      color: mat.get-color-from-palette(mat.$green-palette, 300);
    }

    .rejected {
      color: mat.get-color-from-palette(mat.$red-palette, 900);
    }
  }
}
