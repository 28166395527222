@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-warning-card-component($theme) {
  la-warning-card {
    .card-header {
      color: rgba(176, 0, 32, 1);
    }

    .mat-mdc-card {
      border: 1px solid #e0e0e0;
    }
  }
}
