@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-copy-to-clipboard-component($theme) {
  $primary: map.get($theme, primary);

  la-copy-to-clipboard {
    & .copied {
      color: mat.get-color-from-palette($primary);
    }
  }
}
