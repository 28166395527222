@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-angle-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, primary);

  la-angle {
    .preview {
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .angle {
      background-color: mat.get-color-from-palette($primary, 400);
    }
  }
}
