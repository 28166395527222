/* You can add global styles to this file, and also import other style files */
@import '~ol/ol.css';
@import './styles/material';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.multi-line-tooltip {
  white-space: pre-line;
  font-size: 12px;
  line-height: 16px;
}

.menu-size-350 {
  max-width: 400px !important;
  min-width: 350px !important;
  max-height: calc(100vh - 128px);
}

.menu-size-300 {
  max-width: 350px !important;
  min-width: 300px !important;
  max-height: calc(100vh - 128px);
}

.menu-size-200 {
  max-width: 200px !important;
  min-width: 200px !important;
  max-height: calc(100vh - 128px);
}

.mat-expansion-body-custom-padding .mat-expansion-panel-body {
  padding-left: 0;
  padding-right: 0;
}

.mat-dialog-custom-padding .mat-dialog-container {
  padding: 0;
}
