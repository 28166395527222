@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-filter-bar-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-filter-bar {
    mat-icon {
      color: mat.get-color-from-palette($foreground, 'icon');
    }

    button.secondary {
      color: mat.get-color-from-palette(mat.$grey-palette, 600) !important;
    }

    .action-icon {
      color: mat.get-color-from-palette(mat.$grey-palette, 'A100');
    }
  }
}
