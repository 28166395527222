@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-maps-owned-page-component($theme) {
  $primary: map.get($theme, 'primary');

  la-maps-owned-page {
    .empty {
      color: mat.get-color-from-palette($primary, 300);
    }
  }
}
