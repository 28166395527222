@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metric-line-bar-component($theme) {
  la-metric-line-bar {
    .baseline {
      color: #62c0c5;
    }

    .future::before {
      background-color: #365365;
    }

    .future span {
      color: #365365;
    }

    .future span.uplift {
      color: #365365;
    }

    .future.red span.uplift,
    .future.red span.uplift span.sign {
      color: #b00020;
    }

    .future.green span.uplift,
    .future.green span.uplift span.sign {
      color: #82ae85;
    }

    .future.feature_card {
      color: #365365;
    }
  }
}
