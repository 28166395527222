@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-subscription-purchase-summary-component($theme) {
  $primary: map.get($theme, 'primary');

  la-subscription-purchase-summary {
    border: 2px solid mat.get-color-from-palette($primary);

    .title {
      color: mat.get-color-from-palette($primary);
    }
  }
}
