@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-feature-card-component($theme) {
  la-feature-card {
    .mat-mdc-card {
      border: 1px solid #e0e0e0;
    }

    p {
      color: #666666;
    }
    .icons {
      .distinctiveness {
        &.very-low {
          color: rgba(176, 0, 32, 1);
          background: rgba(176, 0, 32, 0.16);
        }
        &.low {
          color: rgba(236, 169, 114, 1);
          background: rgba(236, 169, 114, 0.16);
        }
        &.medium {
          color: rgba(255, 210, 72, 1);
          background: rgba(255, 210, 72, 0.16);
        }
        &.high {
          color: rgba(130, 174, 133, 1);
          background: rgba(130, 174, 133, 0.16);
        }
        &.very-high {
          color: rgba(104, 139, 107, 1);
          background: rgba(104, 139, 107, 0.16);
        }
      }
      .productive {
        color: rgba(38, 62, 50, 1);
        background-color: rgba(243, 243, 243, 1);
      }
      .biodiversity {
        color: rgba(98, 192, 197, 1);
        background-color: rgba(243, 243, 243, 1);
      }
    }
  }
}
