@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-secondary-codes-component($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-secondary-codes {
    & .search {
      & mat-icon {
        color: mat.get-color-from-palette($foreground, 'base');
      }

      & input {
        border: 1px solid mat.get-color-from-palette($background, 'selected-disabled-button');
      }
    }

    & mat-expansion-panel,
    & .code,
    & .code:first-child:hover,
    & footer {
      border-top: 1px solid mat.get-color-from-palette($background, 'disabled-button');
    }

    & .code:last-child:hover {
      border-bottom: 1px solid mat.get-color-from-palette($background, 'disabled-button');
    }
  }
}
