@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-discount-banner-component($theme) {
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');
  $foreground: map.get($theme, 'foreground');

  la-discount-banner {
    .highiest {
      background-color: mat.get-color-from-palette($primary, 400);
      color: mat.get-color-from-palette($primary, 50);
    }

    .upgrade {
      background-color: mat.get-color-from-palette($accent, 400);
    }

    a {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
