@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-features-attributes-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, 'foreground');

  la-features-attributes {
    mat-list-item {
      & > label {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
    }

    .mat-tab-label-content {
      color: mat.get-color-from-palette($primary, 'text');
    }

    .empty {
      color: mat.get-color-from-palette($foreground, 'slider-off-active');
    }
  }
}
