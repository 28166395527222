@use '@angular/material' as mat;
@import './themes';
@import './elements';
@import './components';
@import './ol';

// Include the default theme styles.
@include mat.all-component-themes($la-light-theme);
@include la-custom-elements($la-light-theme);
@include la-ol-elements($la-light-theme);
@include la-custom-components($la-light-theme);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.la-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.la-dark-theme {
  @include mat.all-component-colors($la-dark-theme);
  @include la-custom-elements($la-dark-theme);
  @include la-ol-elements($la-dark-theme);
  @include la-custom-components($la-dark-theme);
}
