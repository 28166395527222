@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-subscription-enable-land-app-api-page-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-subscription-enable-land-app-api-page {
    .actions {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
