@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-upload-photos-dialog-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-upload-photos-dialog {
    footer {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .uploader {
      border: 3px dashed mat.get-color-from-palette($foreground, 'divider');
    }

    .uploading {
      border: 3px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
