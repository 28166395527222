@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-invitations-team-tags-component($theme) {
  $primary: map.get($theme, 'primary');

  la-invitations-team-tags {
    .mat-hint {
      color: mat.get-color-from-palette($primary);
    }
  }
}
