@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-upgrade-subscription-section-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-upgrade-subscription-section {
    .subscription-cycle {
      background-color: mat.get-color-from-palette($background, card);
    }
    .label {
      background-color: rgba(158, 158, 158, 1);
      color: mat.get-color-from-palette($background, 'background');
    }
    .label.active {
      background-color: mat.get-color-from-palette($primary);
    }

    .mat-label {
      color: mat.get-color-from-palette($primary);
    }
  }
}
