@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-share-map-dialog-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');
  $primary: map.get($theme, 'primary');

  la-share-map-dialog {
    table {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    tr {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .extra-line,
    .suspended {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .invite-people {
      background-color: mat.get-color-from-palette($background, 'app-bar');
    }

    .refer-friend .primary {
      color: mat.get-color-from-palette($primary);
    }
  }
}
