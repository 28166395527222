@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-maps-table-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-maps-table {
    .mat-row:hover {
      background-color: mat.get-color-from-palette($primary, 500);

      &.archived:hover {
        background-color: mat.get-color-from-palette($foreground, 'disabled-button');
      }

      .mat-cell {
        color: mat.get-color-from-palette($primary, '500-contrast');
      }
    }

    .mat-column-owner,
    .mat-column-sharing,
    .mat-column-dateCreated,
    .mat-column-dateUpdated,
    .mat-column-actions {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
