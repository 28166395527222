@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-feature-styles-component($theme) {
  $primary: map.get($theme, primary);

  la-feature-styles {
    .mat-tab-label-content {
      color: mat.get-color-from-palette($primary, 'text');
    }

    .pattern-preview {
      border: 2px solid #f1f1f1;
    }

    .pattern-preview.selected {
      border-color: #ffca28;
    }

    .pattern-button-wrapper {
      text-align: center;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
}
