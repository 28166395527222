@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-workflow-form-autocomplete-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-workflow-form-autocomplete {
    .search-results {
      background-color: mat.get-color-from-palette($background, 'card');
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }

    .description {
      color: mat.get-color-from-palette($primary, 500);
    }

    label,
    span small,
    mat-icon {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
