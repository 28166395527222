@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-error-section-component($theme) {
  $warn: map.get($theme, warn);

  la-error-section {
    span {
      color: mat.get-color-from-palette($warn, 'text');
    }
  }
}
