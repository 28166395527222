@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-projects-table-component($theme) {
  $background: map.get($theme, 'background');

  la-projects-table {
    .report__category-data {
      background-color: mat.get-color-from-palette($background, 'disabled-list-option');
    }
  }
}
