@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-prints-shared-page-component($theme) {
  $primary: map.get($theme, 'primary');

  la-prints-shared-page {
    .empty {
      color: mat.get-color-from-palette($primary, 300);
    }
  }
}
