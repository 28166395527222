@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metric-card-component($theme) {
  la-metric-card {
    .mat-mdc-card {
      border: 1px solid #e0e0e0;
    }

    p {
      color: #666666;
    }
  }
}
