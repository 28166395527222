@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metrics-section-component($theme) {
  la-metrics-section {
    .mat-mdc-card {
      border: 1px solid #e0e0e0;
    }
  }
}
