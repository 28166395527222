@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-usage-section-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-usage-section {
    .link {
      color: mat.get-color-from-palette($primary);
    }

    .api-key-actions {
      mat-icon {
        color: mat.get-color-from-palette(mat.$gray-palette, 800);
      }
    }
  }
}
