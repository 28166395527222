@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-nature-reporting-page-component($theme) {
  $accent: map.get($theme, 'accent');

  la-nature-reporting-page {
    .indicator {
      background-color: mat.get-color-from-palette($accent, 400);
    }
  }
}
