@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-chart-learn-more-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-chart-learn-more {
    .objective {
      color: rgba(98, 192, 197, 1);
    }

    mat-expansion-panel {
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
