@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-generated-links-list-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-generated-links-list {
    & .generated-link {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');

      &:last-child {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
      }

      & a {
        color: mat.get-color-from-palette($primary);
      }

      & a.disabled {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      & div {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
    }
  }
}
