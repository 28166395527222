@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-maps-actions-component($theme) {
  la-maps-actions {
    .delete-button {
      color: rgba(241, 39, 57, 1);

      .mat-icon {
        color: rgba(241, 39, 57, 1);
      }
    }
  }
}
