@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-toolbox-panel-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-toolbox-panel {
    .workflow {
      border: 2px solid mat.get-color-from-palette($foreground, 'slider-off');

      &:hover {
        border: 2px solid mat.get-color-from-palette($primary);
      }
    }

    .disabled {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
      background-color: mat.get-color-from-palette(mat.$grey-palette, 300);
    }
  }
}
