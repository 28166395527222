@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-toolbar-basemaps-component($theme) {
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');

  .mat-menu-item.active-basemap {
    border-left-color: mat.get-color-from-palette($primary);
  }

  .freemium {
    background-color: mat.get-color-from-palette($accent, 400);
  }
}
