@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-import-from-picker-component($theme) {
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-import-from-picker {
    & header .step {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($background, 'card');
    }

    & .select-switcher label {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
