@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-teams-list-component($theme) {
  $primary: map.get($theme, 'primary');

  la-teams-list {
    .add__new {
      color: mat.get-color-from-palette($primary);
    }
  }
}
