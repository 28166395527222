@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-footer-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-footer {
    color: mat.get-color-from-palette($foreground, 'secondary-text');
  }
}
