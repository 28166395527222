@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-download-purchased-data-dialog-component($theme) {
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  la-download-purchased-data-dialog {
    .format {
      background-color: mat.get-color-from-palette($background, 'app-bar');

      & > p {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      & mat-select {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, 'slider-off-active');
      }
    }
  }
}
