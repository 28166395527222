@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-create-plans-confirmation-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $warn: map.get($theme, warn);

  la-create-plans-confirmation {
    border: 2px solid mat.get-color-from-palette($foreground, 'divider');

    .status {
      color: mat.get-color-from-palette($primary, 'text');

      &.error {
        color: mat.get-color-from-palette($warn, 'text');
      }
    }

    .extension,
    .subtype {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
