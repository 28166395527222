@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-members-list-page-component($theme) {
  $primary: map.get($theme, 'primary');

  la-users-page {
    .form__reset {
      color: mat.get-color-from-palette($primary);
    }
  }
}
