@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-billings-list-component($theme) {
  $primary: map.get($theme, 'primary');

  la-billings-list {
    .mat-header-cell {
      color: mat.get-color-from-palette($primary);
    }
  }
}
