@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-change-user-email-success-component($theme) {
  $primary: map.get($theme, 'primary');

  la-change-user-email-success {
    color: mat.get-color-from-palette($primary);
  }
}
