@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-summary-table-component($theme) {
  $background: map.get($theme, 'background');
  $is-dark: map.get($theme, 'is-dark');

  la-summary-table {
    table {
      @if $is-dark {
        background-color: mat.get-color-from-palette($background, 'background');
      } @else {
        background-color: mat.get-color-from-palette($background, 'card');
      }
    }
  }
}
