@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-document-component($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, 'foreground');

  la-document {
    .more {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    div {
      & a,
      & span {
        color: mat.get-color-from-palette($primary);
      }
      & mat-icon {
        color: mat.get-color-from-palette($foreground, 'text');
      }
    }
  }
}
