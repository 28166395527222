@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-subscription-tier-card-component($theme) {
  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');

  la-subscription-tier-card {
    mat-card-header {
      color: mat.get-color-from-palette($background, 'background');
      .original {
        color: #ffffff9e;
      }

      label {
        background-color: #ffffff1a;
      }
    }
    .free {
      border: 1px solid #5e987f;

      mat-card-header {
        border: 1px solid #5e987f;
        background-color: #5e987f;

        button:disabled {
          background: #ffffff1a;
          border: 1px solid #5e987f1a;
          color: mat.get-color-from-palette($background, 'background');
        }

        .mat-mdc-unelevated-button:not(:disabled) {
          background-color: #5e987f;
          border: 1px solid mat.get-color-from-palette($background, 'background');
          color: mat.get-color-from-palette($background, 'background');
        }

        .mat-mdc-unelevated-button:not(:disabled).upgrade {
          background-color: mat.get-color-from-palette($background, 'background');
          border: 1px solid mat.get-color-from-palette($background, 'background');
          color: #5e987fff;
        }
      }
    }

    .standard {
      border: 1px solid #365365;

      &:before {
        background-color: #f7f965;
        color: #365365;
      }

      mat-card-header {
        border: 1px solid #365365;
        background-color: #365365;

        button:disabled {
          background: #ffffff1a;
          border: 1px solid #3653651a;
          color: mat.get-color-from-palette($background, 'background');
        }

        .mat-mdc-unelevated-button:not(:disabled) {
          background-color: #365365;
          border: 1px solid mat.get-color-from-palette($background, 'background');
          color: mat.get-color-from-palette($background, 'background');
        }

        .mat-mdc-unelevated-button:not(:disabled).upgrade {
          background-color: mat.get-color-from-palette($background, 'background');
          border: 1px solid mat.get-color-from-palette($background, 'background');
          color: #365365;
        }
      }
    }

    .professional {
      border: 1px solid mat.get-color-from-palette($primary);

      mat-card-header {
        border: 1px solid mat.get-color-from-palette($primary);
        background-color: mat.get-color-from-palette($primary);

        button:disabled {
          background: #ffffff1a;
          border: 1px solid rgba(38, 62, 50, 0.1);
          color: mat.get-color-from-palette($background, 'background');
        }

        .mat-mdc-unelevated-button:not(:disabled) {
          background-color: mat.get-color-from-palette($primary);
          border: 1px solid mat.get-color-from-palette($background, 'background');
          color: mat.get-color-from-palette($background, 'background');
        }

        .mat-mdc-unelevated-button:not(:disabled).upgrade {
          background-color: mat.get-color-from-palette($background, 'background');
          border: 1px solid mat.get-color-from-palette($background, 'background');
          color: mat.get-color-from-palette($primary);
        }
      }
    }
  }
}
