@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-layer-source-component($theme) {
  $primary: map.get($theme, primary);

  a {
    color: mat.get-color-from-palette($primary);
  }
}
