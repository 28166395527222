@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-workflow-details-panel-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-workflow-details-panel {
    hr {
      border: none;
      border-top: 1px solid mat.get-color-from-palette($foreground, 'slider-off');
    }
  }
}
