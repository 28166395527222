@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-icon-host-component($theme) {
  la-icon-host {
    mat-icon {
      color: mat.get-color-from-palette(mat.$red-palette, 700);
    }
  }
}
