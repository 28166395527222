@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-print-legend-dialog-component($theme) {
  $primary: map.get($theme, 'primary');

  la-print-legend-dialog {
    & mat-tab-group div {
      background: none;
    }

    & .mat-tab-label-content {
      color: mat.get-color-from-palette($primary, 'text');
    }
  }
}
