@use '@angular/material' as mat;
@use 'sass:map';

/* Styles to be applied to buttons */
$custom-button-typography: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 500,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 'normal',
);

$custom-body1-typography: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 500,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 'normal',
);

$custom-typography: mat.define-typography-config(
  $button: $custom-button-typography,
  $body-1: $custom-body1-typography,
);
