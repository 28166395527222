@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-line-bar-component($theme) {
  la-line-bar {
    .bar {
      background-color: #eeeeee;

      .progress {
        background-color: #62c0c5;
      }

      .marker {
        border: 2px solid #ffffff;
      }

      .fill {
        background-color: #365365;
      }
    }
  }
}
