@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-data-purchases-actions-component($theme) {
  la-data-purchases-actions {
    border-left: 1px solid mat.get-color-from-palette(mat.$gray-palette, 300);

    & > button:nth-of-type(2) {
      border-top: 1px solid mat.get-color-from-palette(mat.$gray-palette, 300);
      border-bottom: 1px solid mat.get-color-from-palette(mat.$gray-palette, 300);
    }
  }
}
