@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-reset-password-required-component($theme) {
  $primary: map.get($theme, 'primary');
  $foreground: map.get($theme, 'foreground');

  la-reset-password-required {
    color: mat.get-color-from-palette($foreground, 'text');
    .title {
      color: mat.get-color-from-palette($primary);
    }
  }
}
