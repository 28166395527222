@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-map-page-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  la-map-page {
    .title small {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.32);
    }
  }
}
