@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-line-type-dropdown-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-line-type-dropdown {
    button.mat-menu-item {
      &:not(:last-of-type):after {
        background-color: mat.get-color-from-palette($foreground, 'text');
      }
    }
  }
}
