@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-no-organization-component($theme) {
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');

  la-no-organization {
    h1 {
      color: mat.get-color-from-palette($primary);
    }

    p {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    a {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
