@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-avatar-icon-component($theme) {
  $background: map.get($theme, 'background');
  $primary: map.get($theme, 'primary');

  la-avatar-icon {
    div {
      color: mat.get-color-from-palette($background, 'card');
      background: mat.get-color-from-palette($primary, 900);
      border: 2px solid mat.get-color-from-palette($background, 'card');
    }

    .number {
      color: mat.get-color-from-palette($primary, 'text');
      background: #d9d9d9;
    }
  }
}
