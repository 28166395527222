@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-metrics-summary-component($theme) {
  $background: map.get($theme, 'background');

  la-metrics-summary {
    background-color: mat.get-color-from-palette($background, 'status-bar');
  }
}
