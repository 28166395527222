@use 'sass:map';
@use '@angular/material' as mat;

@mixin la-name-plans-for-files-component($theme) {
  $foreground: map.get($theme, 'foreground');

  la-name-plans-for-files {
    .subtype,
    .no-files,
    .extension {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .container {
      border: 3px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
